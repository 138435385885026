import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../Portfolio/Portfolio.css";
import { MdPreview } from "react-icons/md";

const Portfolio = () => {
  const [selectedCategory, setSelectedCategory] = useState("Mobile Application");

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  const mobileScreenshots = [
    { src: "think.mp4", url: "https://thinkbuyproperty.com/" },
    { src: "mvp.mp4", url: "https://www.themvpquest.com/" },
    { src: "brosid.mp4", url: "https://brosidsports.com/" },
    { src: "indo.mp4", url: "https://indokings.in/" },
    { src: "medix.mp4", url: "https://infogainsoft.in/" },
    { src: "ace.mp4", url: "https://apexelegant.com/" },
  ];

  const desktopScreenshots = [
    { src: "direccion.mp4", url: "https://www.direccion.in/" },
    { src: "american.mp4", url: "https://americanmavericks.us/" },
    { src: "shree.mp4", url: "https://shreeclassesbly.com/" },
    { src: "m3m.mp4", url: "https://darkcyan-salmon-899600.hostingersite.com/" },
  ];

  const handlePreviewClick = (url) => {
    window.open(url, "_blank", "noopener noreferrer");
  };

  return (
    <>
      <div className="portfolio-section">
        <div className="portfolio-header">
          <h2 className="porfolio-head">
            Mobile app and Web Development Portfolio
          </h2>
          <p className="portfolio-text">
            Complete Your Project Now! No matter your business requirements or
            budget, we're here to assist you in accomplishing them.
          </p>
        </div>

        <div className="toggle-buttons">
          <button
            className={selectedCategory === "Mobile Application" ? "active" : ""}
            onClick={() => handleCategoryChange("Mobile Application")}
          >
            Mobile Application
          </button>
          <button
            className={selectedCategory === "Web Development" ? "active" : ""}
            onClick={() => handleCategoryChange("Web Development")}
          >
            Web Development
          </button>
        </div>
        <div className="divider"></div>
        <div className="projects-container">
  {selectedCategory === "Mobile Application" && (
    <div className="grid-container-sp mobile-grid">
      {mobileScreenshots.map((screenshot, index) => (
        <div className="mobile-frame-sp" key={index}>
          <div className="mobile-header-sp"></div>
          <video
            src={screenshot.src}
            alt={`Mobile View ${index}`}
            className="mobile-content-sp"
            style={{ width: "100%", height: "auto" }}
            autoPlay
            muted
            playsInline
            loop
          />
          <div className="image-overlay"></div>
         {/*-<button
            className="image-button"
            onClick={() => handlePreviewClick(screenshot.url)}
          >
            <MdPreview className="p-icon" /><br />Preview
          </button>
          -*/} 
          <div className="mobile-footer-sp"></div>
        </div>
      ))}
    </div>
  )}
  {selectedCategory === "Web Development" && (
    <div className="grid-container-sp desktop-grid">
      {desktopScreenshots.map((screenshot, index) => (
        <div className="desktop-frame-sp" key={index}>
          <div className="desktop-header-sp"></div>
          <video
            src={screenshot.src}
            alt={`Desktop View ${index}`}
            className="desktop-content-sp"
            style={{ width: "100%", height: "auto" }}
            autoPlay
            muted
            playsInline
            loop
          />
          <div className="image-overlay">
         
          </div>
         {/*-<button
            className="image-button"
            onClick={() => handlePreviewClick(screenshot.url)}
          >
            <MdPreview className="p-icon" /><br />Preview
          </button>
          -*/} 
         
          <div className="desktop-footer-sp"></div>
        </div>
      ))}
    </div>
  )}
</div>

      </div>
      <section className="review-section">
        <h1 className="review-heading">
          Our Clients <span className="review-heading-span">Love Us</span>
        </h1>
        <div className="caro-div">
          <div id="carouselExampleIndicators" className="carousel slide">
            <div className="carousel-indicators">
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to={0}
                className="active"
                aria-current="true"
                aria-label="Slide 1"
              />
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to={1}
                aria-label="Slide 2"
              />
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to={2}
                aria-label="Slide 3"
              />
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to={3}
                aria-label="Slide 4"
              />
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to={4}
                aria-label="Slide 5"
              />
            </div>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div className="text-div">
                  <p className="text1">
                   " Infogainsoft has done a fantastic job! The color scheme is
                    spot on, giving the website a clean and professional look.
                    Every detail has been thoughtfully designed, making it
                    visually appealing and highly functional. Your hard work and
                    dedication are evident in every aspect. The end result truly
                    stands out and enhances our online presence. Thank you for
                    delivering such an impressive result! 👍🏻"
                  </p>
                  <h3 className="text2">Managing Director - ShreeClasses</h3>
                </div>
              </div>
              <div className="carousel-item">
                <div className="text-div">
                  <p className="text1">
                    "Infogainsoft has done an incredible job designing our
                    property website. The site is visually appealing, easy to
                    navigate, and perfectly suited to our brand. The team's
                    creativity and attention to detail have truly made a
                    difference and also give good look and feel. We're thrilled with the final product and
                    grateful for their excellent work! Their dedication and
                    expertise have exceeded our expectations."
                  </p>
                  <h3 className="text2">
                    Managing Director - Think&BuyProperty
                  </h3>
                </div>
              </div>
              <div className="carousel-item">
                <div className="text-div">
                  <p className="text1">
                    "Infogainsoft has delivered an outstanding website for
                    Brosid Sports Cricket League. The design is dynamic and
                    captures the spirit of cricket perfectly. Navigation is
                    smooth, and the layout is both engaging and user-friendly.
                    Their attention to detail and commitment to quality are
                    evident throughout the site. We're extremely pleased with
                    the results and highly recommend their services!"
                  </p>
                  <h3 className="text2">Managing Director - BrosidSports</h3>
                </div>
              </div>
              <div className="carousel-item">
                <div className="text-div">
                  <p className="text1">
                    "Infogainsoft has provided a top-notch design for the
                    Indokings Cricket Team website with good user experience. The site is both visually
                    striking and highly functional, effectively showcasing our
                    team’s identity. Their team's professionalism and attention
                    to detail made the entire process smooth and efficient.
                    We’re thrilled with the final product and the positive
                    impact it has on our online presence."
                  </p>
                  <h3 className="text2">Managing Director - IndoKings</h3>
                </div>
              </div>
              <div className="carousel-item">
                <div className="text-div">
                  <p className="text1">
                    "Infogainsoft has created an exceptional sports website for
                    American Mavericks. Their unique approach to presenting
                    details sets our site apart, offering a fresh and engaging
                    user experience. The design is both innovative and
                    functional, reflecting our brand’s dynamic spirit. We were
                    impressed with their creativity throughout the project. The
                    final result is impressive and highly effective."
                  </p>
                  <h3 className="text2">
                    Managing Director - AmericanMavericks
                  </h3>
                </div>
              </div>
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="prev"
            >
              <span className="carousel-control-prev-icon" aria-hidden="true" />
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="next"
            >
              <span className="carousel-control-next-icon" aria-hidden="true" />
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default Portfolio;
