import React, { useState } from "react";
import emailjs from 'emailjs-com';
import "./contactus.css";
import { MdEmail } from "react-icons/md";
import { FaAddressCard, FaPhoneVolume, FaLocationDot } from "react-icons/fa6";

const Contactus = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    city: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateField = (name, value) => {
    let error = "";
    switch (name) {
      case "firstName":
      case "lastName":
        if (!value.match(/^[a-zA-Z\s]*$/)) {
          error = `${name === "firstName" ? "First" : "Last"} name should contain only letters`;
        }
        break;
      case "email":
        if (!value.match(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/)) {
          error = "Invalid email address";
        }
        break;
      case "mobileNumber":
        if (!value.match(/^\d{10}$/)) {
          error = "Mobile number must be 10 digits";
        }
        break;
      case "city":
        if (!value.match(/^[a-zA-Z\s]*$/)) {
          error = "City should contain only letters";
        }
        break;
      case "message":
        if (value.length < 15) {
          error = "Purpose should contain at least 15 characters";
        }
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    validateField(name, value);
  };

  const validateForm = () => {
    let valid = true;
    Object.keys(formData).forEach((key) => {
      if (formData[key] === "" || errors[key]) {
        valid = false;
        validateField(key, formData[key]);
      }
    });
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsSubmitting(true);  // Disable the submit button
      emailjs.sendForm('service_q1ot86u', 'template_idgfe8i', e.target, '-fuNZXUwv9KZdBB7R')
        .then((result) => {
          console.log(result.text);
          alert("Form submitted successfully!");
        }, (error) => {
          console.log(error.text);
          alert("Form submission failed. Please try again.");
        })
        .finally(() => {
          setIsSubmitting(false);  // Re-enable the submit button
        });
    } else {
      console.log("Form validation failed");
    }
  };
  const calladmin = () => {
    const phoneNumber = 'tel:+917303748900'; 
    window.open(phoneNumber, '_self');
  };
  const handleMailClick = () => {
    window.location.href = 'mailto:info@infogainsoft.com';
  };
  return (
    <>
      <section id="contact" className="contactus-section-main">
        <div className="contact-black-poster">
          <h1 className="contact-black-poster-head text-center">
            Connect with <span className="contact-black-poster-span">InfogainSoft</span> Reach Out to Us Today!
          </h1>
        </div>

        <div className="contactus-main container-fluid">
          <div className="container-3 contact-container">
            <div className="left-div-contact">
              <h2 className="contactus-heading-form1">Connect With Us</h2>
              <div className="contactus-section">
                <div className="contactus-info">
                  <div className="contactus-info-main">
                    <div className="contactus-info-logo">
                      <p>
                        <MdEmail className="icon-contactus" />
                      </p>
                    </div>
                    <div className="contactus-item">
                      <h4 className="text-connect-all">Email</h4>
                      <p className="text-connect-all-para"onClick={handleMailClick}>info@infogainsoft.com</p>
                    </div>
                  </div>
                  <div className="contactus-info-main">
                    <div className="contactus-info-logo">
                      <p>
                        <FaAddressCard className="icon-contactus" />
                      </p>
                    </div>
                    <div className="contactus-item">
                      <h4 className="text-connect-all">Address</h4>
                      <p className="text-connect-all-para">Sector 62 Road, Noida, Uttar Pradesh IN</p>
                    </div>
                  </div>
                  <div className="contactus-info-main">
                    <div className="contactus-info-logo">
                      <p>
                        <FaPhoneVolume className="icon-contactus" />
                      </p>
                    </div>
                    <div className="contactus-item">
                      <h4 className="text-connect-all">Phone</h4>
                      <p className="text-connect-all-para" onClick={calladmin}>+917303748900</p>
                    </div>
                  </div>
                  {/*-
                  <div className="contactus-info-main">
                    <div className="contactus-info-logo">
                      <p>
                        <FaLocationDot className="icon-contactus" />
                      </p>
                    </div>
                    <div className="contactus-item">
                      <h4 className="text-connect-all">Location</h4>
                      <p className="text-connect-all-para">India</p>
                    </div>
                  </div>
                  -*/}
                </div>
              </div>
            </div>
            <div className="right-div-contact">
              <h2 className="contactus-heading-form">Let Us Hear from You</h2>
              <div className="contactus-form">
                <form onSubmit={handleSubmit}>
                  <div className="horizontal-contactus">
                    <div className="form-group-contactus">
                      <label className="label-contactus" htmlFor="firstName">First Name:</label>
                      <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        className="contactus-input"
                        value={formData.firstName}
                        onChange={handleChange}
                        required
                      />
                      {errors.firstName && <p className="error">{errors.firstName}</p>}
                    </div>

                    <div className="form-group-contactus">
                      <label className="label-contactus" htmlFor="lastName">Last Name:</label>
                      <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        className="contactus-input"
                        value={formData.lastName}
                        onChange={handleChange}
                        required
                      />
                      {errors.lastName && <p className="error">{errors.lastName}</p>}
                    </div>
                  </div>
                  <div className="horizontal-contactus">
                    <div className="form-group-contactus">
                      <label className="label-contactus" htmlFor="email">Email:</label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="contactus-input"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                      {errors.email && <p className="error">{errors.email}</p>}
                    </div>
                    <div className="form-group-contactus">
                      <label className="label-contactus" htmlFor="mobileNumber">Mobile Number:</label>
                      <input
                        type="tel"
                        id="mobileNumber"
                        name="mobileNumber"
                        className="contactus-input"
                        value={formData.mobileNumber}
                        onChange={handleChange}
                        required
                      />
                      {errors.mobileNumber && <p className="error">{errors.mobileNumber}</p>}
                    </div>
                  </div>
                  <div className="form-group-contactus">
                    <label className="label-contactus" htmlFor="city">City:</label>
                    <input
                      type="text"
                      id="city"
                      name="city"
                      className="contactus-input"
                      value={formData.city}
                      onChange={handleChange}
                      required
                    />
                    {errors.city && <p className="error">{errors.city}</p>}
                  </div>
                  <div className="form-group-contactus">
                    <label className="label-contactus" htmlFor="message">Purpose:</label>
                    <textarea
                      id="message"
                      name="message"
                      className="contactus-textarea"
                      value={formData.message}
                      onChange={handleChange}
                      required
                    />
                    {errors.message && <p className="error">{errors.message}</p>}
                  </div>
                  <button className="contactus-submit" type="submit" disabled={isSubmitting}>
                    {isSubmitting ? "Submitting..." : "Submit"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contactus;
