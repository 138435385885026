import React, { useState } from "react";
import "../Services/Services.css";
import { FaRegArrowAltCircleRight } from "react-icons/fa";
import { FaCode } from "react-icons/fa";
import { IoCloudDoneSharp } from "react-icons/io5";
import { FaDigitalOcean } from "react-icons/fa";
import { CgWebsite } from "react-icons/cg";
import { MdOutlineSocialDistance } from "react-icons/md";
import { SiTaichigraphics } from "react-icons/si";
const Servicesall = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ heading: "", text: "" });

  const openModal = (heading, text) => {
    setModalContent({ heading, text });
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <section id="sit" className="our-servicesall">
        {modalOpen && (
          <div className="modal">
            <div className="modal-content">
              <span className="close" onClick={closeModal}>
                &times;
              </span>
              <h2 className="modal-head">{modalContent.heading}</h2>
              <p className="modal-text">{modalContent.text}</p>
            </div>
          </div>
        )}

        <div className="services-container">
          <div className="about-company">
            <h2 className="center-top">
              About <span className="center-top-span">Infogainsoft</span>
            </h2>
            <h3 className="sub-heading align-center">
              A Legacy of Excellence and Innovation in IT Solutions
            </h3>
            <p className="paragraph align-center">
              Infogainsoft is committed to pioneering excellence and innovation
              in the realm of IT solutions. With a rich legacy of delivering
              cutting-edge services, including software development, cloud
              solutions, digital strategy, and more, we empower businesses to
              thrive in the digital era. Our track record of success and
              unwavering dedication to client satisfaction make us a trusted
              partner for organizations looking to unlock their full potential
              and achieve lasting success.
            </p>
          </div>

          <div className="our-services-heading">
            <h2 className="center-top-services">Our Services</h2>
          </div>
          <div className="services-cards">
           
            
           
            <div
              className="services-card"
              onClick={() =>
                openModal(
                  "Website Creation",
                  "Your website is the online face of your business, and first impressions matter. That's why our website creation services are designed to bring your vision to life online, with stunning designs that captivate and engage your audience. Our experienced team combines creative flair with technical expertise to deliver websites that not only look great but also perform seamlessly across all devices. With top-tier security integration, you can rest assured that your online presence is protected against threats, allowing you to focus on what you do best – growing your business."
                )
              }
            >
              <h3>
                Website Creation <CgWebsite />
              </h3>
              <p>
              Crafting stunning websites tailored to your vision, with top-tier security integration.
              </p>
              <a href="#" className="btn-service">
                <FaRegArrowAltCircleRight className="icon-services" />
              </a>
            </div>
            <div
              className="services-card"
              onClick={() =>
                openModal(
                  "Social Media Marketing",
                  "In today's digital world, a strong social media presence is essential for success. Our expert social media marketing strategies are designed to elevate your brand's online presence, connecting you with your target audience and driving meaningful engagement. From content creation to community management, we'll help you tell your brand story in a way that resonates with your audience, building brand loyalty and driving results for your business. With our data-driven approach, you'll have the insights you need to optimize your social media strategy and stay ahead of the competition."
                )
              }
            >
              <h3>
                Social Media Marketing <MdOutlineSocialDistance />
              </h3>
              <p>
              Elevate your brand's online presence with our expert social media marketing strategies.
              </p>
              <a href="#" className="btn-service">
                <FaRegArrowAltCircleRight className="icon-services" />
              </a>
            </div>
            <div
              className="services-card"
              onClick={() =>
                openModal(
                  "Graphic Designing",
                  "Make a lasting impression with our comprehensive graphic design services. From eye-catching logos to dynamic presentations, our talented team will help you unlock your brand's full potential with visually striking designs that stand out from the crowd. Whether you're launching a new product, promoting an event, or refreshing your brand identity, we'll work closely with you to bring your vision to life in a way that captures the essence of your brand and resonates with your audience. With our attention to detail and commitment to quality, you can trust us to deliver designs that make an impact and drive results for your business."
                )
              }
            >
              <h3>
                Graphic Designing <SiTaichigraphics />
              </h3>
              <p>
              Unlock your brand's potential with our comprehensive graphic design services: logos, pamphlets, and dynamic presentations.
              </p>
              <a href="#" className="btn-service">
                <FaRegArrowAltCircleRight className="icon-services" />
              </a>
            </div>


            
            <div
              className="services-card"
              onClick={() =>
                openModal(
                  "Online Web Security (VAPT)",
                  "Protecting your digital assets is more important than ever in today's interconnected world. Our robust online web security solutions provide comprehensive protection against cyber threats, keeping your data safe and secure around the clock. From proactive threat detection to real-time monitoring and response, we take a proactive approach to security, empowering you to focus on your business with confidence knowing that your online assets are fully protected."
                )
              }
            >
              <h3>
                Online Web Security <FaDigitalOcean />
              </h3>
              <p>
                Protect your digital assets with our robust online web security
                solutions today!
              </p>
              <a href="#" className="btn-service">
                <FaRegArrowAltCircleRight className="icon-services" />
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Servicesall;
