// ChatComponent.js
import React, { useState } from 'react';
import '../Chatbox/Chat.css'
import { IoLogoWhatsapp } from "react-icons/io";
import { MdOutlineWifiCalling3 } from "react-icons/md";
const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState('');

 
  const handleWhatsAppClick = () => {

    const url = `https://api.whatsapp.com/send/?phone=%2B917303748900&text&type=phone_number&app_absent=0`;
    window.open(url, '_blank');
  };
  const calladmin = () => {
    const phoneNumber = 'tel:+917303748900'; 
    window.open(phoneNumber, '_self');
  };

  return (
    <>
    <div className="chat-component">
      <div className="messages">
        {/* Render messages here */}
      </div>

      <IoLogoWhatsapp className='icon-chat' onClick={handleWhatsAppClick}/>
    
    </div>
    <div className="chat-component2">
      <div className="messages">
        {/* Render messages here */}
      </div>

      
      <MdOutlineWifiCalling3 className='icon-chat' onClick={calladmin}/>
    </div>
    </>
  );
};

export default Chat;
