import React,{useEffect} from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { BrowserRouter as Router, Routes, Route ,useLocation } from "react-router-dom";
import "./App.css";
import Home from "./Components/Home/home";
import Navbar from "./Components/Navbar/navbar";
import Footer from "./Components/Footer/footer";
import Portfolio from "./Components/Portfolio/Portfolio";
import Demo from "./Components/Demo/Demo";
import Contactus from "./Components/Contact/contactus";
import Services from "./Components/Services/Services";
import About from "./Components/Aboutus/About";
import Servicesall from "./Components/Servicesall/Servicesall";
import Career from "./Components/Career/Career";
import Chat from "./Components/Chatbox/Chat";
AOS.init({
  duration: 1000,
});

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'  
    });
  }, [pathname]);

  return null;
}
function App() {
  return (
    <Router>
      <Navbar />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/demo" element={<Demo />} />
        <Route path="/contactus" element={<Contactus />} />
        <Route path="/services" element={<Services />} />
        <Route path="/servicesall" element={<Servicesall />} />
        <Route path="/aboutus" element={<About />} />
        <Route path="/career" element={<Career />} />
      </Routes>
      <Chat/>
      <Footer />
    </Router>
  );
}

export default App;
