import React from "react";
import "../Navbar/navbar.css";
import { Link } from "react-router-dom";


const Navbar = () => {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light navi">
      
        <div className="container-fluid ">
          <a className="navbar-brand text-white" href="/">
            <img src="inf.png" className="logo" alt="home-logo" />
           
          </a>
          <div className="main-flex">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="main-link-div">
            <div
              className="collapse navbar-collapse justify-content-center"
              id="navbarNav"
            >
              <ul className="navbar-nav ">
                <li className="nav-item ">
                  <a className="nav-link active" aria-current="page" href="/">
                    Home
                  </a>
                </li>
                <li className="nav-item ">
                  <a
                    className="nav-link active"
                    aria-current="page"
                    href="/aboutus"
                  >
                    About Us
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link active" href="/servicesall">
                    Services
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link active" href="/portfolio">
                    Portfolio
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link active" href="/career">
                    Career
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link active" href="/contactus">
                    Contact Us
                  </a>
                </li>
                <li>
                  <Link to="/demo">
                    <button className="nav-button">Demo</button>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
