import React, { useState } from "react";
import "../Demo/Demo.css";
import emailjs from "emailjs-com";

const Demo = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [requirements, setRequirements] = useState("");

  const [errors, setErrors] = useState({});

  const validateField = (name, value) => {
    let error = "";
    switch (name) {
      case "fullName":
        if (!value.match(/^[a-zA-Z\s]*$/)) {
          error = "Full name should contain only letters and spaces";
        }
        break;
      case "email":
        if (!value.match(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/)) {
          error = "Invalid email address";
        }
        break;
      case "phoneNumber":
        if (!value.match(/^\d{10}$/)) {
          error = "Phone number must be 10 digits";
        }
        break;
      case "companyName":
        if (!value.match(/^[a-zA-Z0-9\s]*$/)) {
          error = "Company name should contain only letters and numbers";
        }
        break;
      case "requirements":
        if (value.length < 15) {
          error = "Requirements should be at least 15 characters";
        }
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "fullName":
        setFullName(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "phoneNumber":
        setPhoneNumber(value);
        break;
      case "companyName":
        setCompanyName(value);
        break;
      case "requirements":
        setRequirements(value);
        break;
      default:
        break;
    }
    validateField(name, value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = Object.values(errors).every((error) => error === "") && 
                    fullName && email && phoneNumber && companyName && requirements;

    if (isValid) {
      // Send form data using EmailJS
      emailjs.send("service_q1ot86u", "template_idgfe8i", {
        fullName,
        email,
        phoneNumber,
        companyName,
        requirements
      }, "-fuNZXUwv9KZdBB7R")
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
      }, (err) => {
        console.log('FAILED...', err);
      });

      // Clear form
      setFullName("");
      setEmail("");
      setPhoneNumber("");
      setCompanyName("");
      setRequirements("");
      setErrors({});
    } else {
      console.log("Form validation failed");
    }
  };

  return (
    <div className="demo-section">
      <h1 className="demo-head">
        Welcome to Our <span className="demo-head-span">Demo</span>
      </h1>
      <p className="demo-text">Fill out the form below to request a demo.</p>
      <div className="demo">
        <form onSubmit={handleSubmit}>
          <div className="name-email">
            <div>
              <label htmlFor="fullName">Full Name:</label>
              <input
                type="text"
                id="fullName"
                name="fullName"
                value={fullName}
                onChange={handleChange}
                required
              />
              {errors.fullName && <p className="error">{errors.fullName}</p>}
            </div>
            <div>
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={handleChange}
                required
              />
              {errors.email && <p className="error">{errors.email}</p>}
            </div>
          </div>
          <div className="phone-company">
            <div>
              <label htmlFor="phoneNumber">Phone Number:</label>
              <input
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                value={phoneNumber}
                onChange={handleChange}
                required
              />
              {errors.phoneNumber && (
                <p className="error">{errors.phoneNumber}</p>
              )}
            </div>
            <div>
              <label htmlFor="companyName">Company Name:</label>
              <input
                type="text"
                id="companyName"
                name="companyName"
                value={companyName}
                onChange={handleChange}
                required
              />
              {errors.companyName && (
                <p className="error">{errors.companyName}</p>
              )}
            </div>
          </div>
          <label htmlFor="requirements">
            Tell us more about your requirements:
          </label>
          <textarea
            id="requirements"
            name="requirements"
            value={requirements}
            onChange={handleChange}
            required
          ></textarea>
          {errors.requirements && (
            <p className="error">{errors.requirements}</p>
          )}
          <div className="button-demos">
            <button type="submit">Request Demo</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Demo;
