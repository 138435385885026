import React, { useState } from 'react';
import '../Career/Career.css';
import { FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
const Career = () => {
  const [selectedButton, setSelectedButton] = useState(0);

  // Array of button names
  const buttonNames = [
    'Impact',
    'Development',
    'Support',
    'Progress',
    
  ];

  // Content array with image, heading, and text
  const content = [
    {
      image: 'impact.jpg',
      heading: 'Leading with purpose',
      text: 'Through the application of innovation and our contextual knowledge, we give associates the opportunity to deliver transformative outcomes that benefit society at large and prove that anything is possible.'
    },
    {
      image: 'development.jpg',
      heading: 'Continuous learning',
      text: 'We equip our associates to deliver innovative solutions, by providing them with opportunities to access and learn from the vast collective experience that exists within TCS. We ensure they remain at the cutting edge of change.'
    },
    {
      image: 'support.jpg',
      heading: 'Support',
      text: 'Through upskilling and reskilling, and with opportunities to move across the business, our people, regardless of age or stage of their career, are supported to discover and become the professionals they were meant to be.'
    },
    {
      image: 'progress.jpg',
      heading: 'Progress',
      text: 'We see our people as long-term relationships that we build together and from which we all grow. We invest in them across the duration of their career and encourage them to strive for perpetual progress.'
    },
    {
      image: 'wd1.jpg',
      heading: 'Heading 5',
      text: 'Text for the fifth button.'
    },
  ];

  return (
    <>
      <div className="career-section-sp">
        <video className="career-video-sp" autoPlay loop muted>
          <source src="career.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="overlay-career-sp">
          <h1 className="career-heading-sp">Shape a future <br /> you have faith in...</h1>
         <Link to="/contactus"><button className="apply-now-button-sp">
            Apply Now <FaArrowRight className="arrow-icon" />
          </button>
          </Link> 
        </div>
      </div>
      <section className="why-infogainsoft-sp">
        <h2 className="why-infogainsoft-sp__heading">Why Infogainsoft?</h2>
        <div className="why-infogainsoft-sp__buttons">
          {buttonNames.map((name, index) => (
            <button
              key={index}
              className={`why-infogainsoft-sp__button ${selectedButton === index ? 'active' : ''}`}
              onClick={() => setSelectedButton(index)}
              aria-selected={selectedButton === index}
              aria-controls={`content-${index}`}
            >
              {name}
            </button>
          ))}
        </div>
        <div className="why-infogainsoft-sp__content">
          <div className="why-infogainsoft-sp__image">
            <img src={content[selectedButton].image} alt={content[selectedButton].heading} />
          </div>
          <div className="why-infogainsoft-sp__text">
            <h3>{content[selectedButton].heading}</h3>
            <p>{content[selectedButton].text}</p>
          </div>
        </div>
      </section>
      {/*-
      <div className="f-message">
            <div className="message-content">
            <img src='https://www.tcs.com/etc.clientlibs/tcs/clientlibs/clientlib-site/resources/images/quote.svg' alt='logo' className='comma'/>
                <h2 className='h2-career'>
                    Our investment in strategic talent development initiatives and the linking of learning to career development have energized our workforce. Our empowering, performance-driven work culture is helping us attract local talent across all our key markets.
                </h2>
                <div className="divider-c"></div>
                <div className="founder-info">
                    <div className="founder-image">
                        <img src="tcs.png" alt="Founder" />
                    </div>
                    <div className="founder-details">
                        <h3 className="founder-name">Founder Name</h3>
                        <p className="founder-post">Founder & CEO</p>
                    </div>
                </div>
            </div>
        </div>
        -*/}
        <section className="apply-c">
      <div className="left-apc">
        <img src="pic.jpg" alt="Cover" />
      </div>
      <div className="right-apc">
        <h2>Greater futures through innovation</h2>
        <p>Don't hesitate to share your basic information to receive updates about our career opportunities</p>
        <h3>
            <a href="mailto:Jobs@infogainsoft.com" className="email-link">Jobs@infogainsoft.com</a>
          </h3>
      </div>
    </section>
    </>
  );
};

export default Career;
