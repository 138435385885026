import React from "react";
import {Link} from 'react-router-dom';
import "../Footer/footer.css";
import { FaFacebook, FaLinkedin } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaSquareWhatsapp } from "react-icons/fa6";
const Footer = () => {
  const calladmin = () => {
    const phoneNumber = 'tel:+91 7303748900'; 
    window.open(phoneNumber, '_self');
  };
  return (
    <>
      <footer className="bg-dark">
        <div className="container-footer">
          <div className="row-footer">
            <div className="col-md-4">
              <h3 className="head-foot">About Company</h3>
              <p className="about-text">
                Infogainsoft leads the charge in delivering innovative IT
                solutions, empowering businesses to excel in the digital age
                with unparalleled expertise and dedication to client
                satisfaction.
              </p>
            </div>
            <div className="col-md-4 tt">
              <h3 className="head-foot">Links</h3>
              <ul className="service-list">
              <Link to="/">
                <li className="service-item">Home</li>
                </Link>
                <Link to="/aboutus">
                <li className="service-item">About Us</li>
                </Link>
                <Link to="/servicesall">
                <li className="service-item">Services</li>
                </Link>
                <Link to="/portfolio">
                <li className="service-item">Portfolio</li>
                </Link>
                <Link to="/career">
                <li className="service-item">Career</li>
                </Link>
                <Link to="/contactus">
                <li className="service-item">Contact</li>
                </Link>
              </ul>
            </div>
            <div className="col-md-4">
              <h3 className="head-foot">Services</h3>
              <ul className="service-list">
                <li className="service-item">Website Creation</li>
                <li className="service-item">Social Media Marketing</li>
                <li className="service-item">Graphic Designing</li>
                <li className="service-item">Online Web Security</li>
              
              </ul>
            </div>
            <div className="get-all">
            <div className="col-md-4-1">
              <h3 className="head-foot">Get in Touch</h3>
              
              <p className="about-text"><span className="about-text-span">Address:</span>Sector 62
              Road, Noida, Uttar Pradesh IN</p> 
              <p className="about-text">
  <span className="about-text-span">Email:</span>{" "}
  <a href="mailto:info@infogainsoft.com">info@infogainsoft.com</a>
</p>
              <p  className="about-text" onClick={calladmin}><span className="about-text-span">Mobile:</span> +917303748900{" "}</p>
              <div className="get-in-logo">
            <ul className="navbar-nav-2 ">
            <li className="nav-item">
              <a className="nav-link-2 active" target="_blank" href="https://www.instagram.com/infogainsoft/?igsh=OWxqMmhrYnUxaXN1&utm_source=qr">
                <FaInstagramSquare className="icon-navbar-top" />
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link-2 active" target="_blank" href="https://www.facebook.com/people/Infogainsoft/100082269813889/">
                <FaFacebook className="icon-navbar-top" />
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link-2 active" target="_blank" href="https://api.whatsapp.com/send/?phone=%2B917303748900&text&type=phone_number&app_absent=0">
                <FaSquareWhatsapp className="icon-navbar-top" />
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link-2 active" target="_blank" href="https://www.linkedin.com/company/infogainsoft/mycompany/">
                <FaLinkedin className="icon-navbar-top" />
              </a>
            </li>
           
          </ul>
            </div>
            </div>
            
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container-footer1">
            <div className="row-footer">
              <div className="col-md-12 text-center">
                <hr className="footer-divider" />
                <div className="footer-main">
                  <p className="copyright">
                    &copy; {new Date().getFullYear()} Infogainsoft. All Rights
                    Reserved.
                  </p>
                  <p className="privacy-policy">
                    <a href="/privacy-policy">Privacy Policy</a>{" "}
                    <a href="/terms">Terms & Conditions</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
