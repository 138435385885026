import React , {useEffect} from 'react'
import '../Association/Association.css'
const Association = () => {
  useEffect(() => {
    const photoWrapper = document.getElementById("photoWrapper");
    const photos = Array.from(document.getElementsByClassName("photo"));

    let count = 0;
    photos.forEach((photo) => {
        count++;
        if (count % 2) {
            photo.classList.add("even");
        }
    });

    const checkPosition = (photo) => {
      if (photo.getBoundingClientRect().right - 4 <= 0) {
          photo.remove();
          photoWrapper.append(photo);
          photoWrapper.scrollLeft = 0;
          return;
      }
    };

    const handleScroll = () => {
      photos.forEach(checkPosition);
    };

    photoWrapper.addEventListener("scroll", handleScroll);

    const infiniteScroll = () => {
      photoWrapper.scrollLeft++;
      requestAnimationFrame(infiniteScroll);
    };

    infiniteScroll();

    return () => {
      photoWrapper.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <section className='our-association-section'>
     
    
</section>
    </>
  )
}

export default Association